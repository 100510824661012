import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
import { Box } from '@primer/react';
import { Caption } from '@primer/gatsby-theme-doctocat';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <p>{`Dialogs can streamline the interface by allowing extra content to be disclosed as needed. Dialogs create a new modality to the user, and can expedite the completion of individual tasks.`}</p>
    <h2>{`Anatomy`}</h2>
    <img width="960" alt="" src="https://user-images.githubusercontent.com/18661030/185509649-50791e2f-0a4c-44d9-8b2b-e34b3f394315.png" />
    <Caption mdxType="Caption">Anatomy of a Dialog.</Caption>
    <h3>{`Header region`}</h3>
    <p>{`The `}<strong parentName="p">{`header`}</strong>{` region provides context for the Dialog. By default, the region supports a title, description, and a close button. Titles and descriptions may wrap to multiple lines if necessary.`}</p>
    <p>{`The title is required for all Dialogs, but may be visually hidden for custom scenarios such as a command palette. The close button is required and may `}<strong parentName="p">{`not`}</strong>{` be visually hidden. The title has a default size along with a larger option for confirmation dialogs.`}</p>
    <p>{`The header region provides a slot for custom content in place of the default title/description, though a title is still required and may be visually hidden. Secondary action icon buttons may be placed next to the close button.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} marginBottom={5} mdxType="Box">
  <div>
    <img width="464" src="https://user-images.githubusercontent.com/293280/175849372-a9d33d3b-96f3-497e-bad6-70df4d91072a.png" alt="" />
    <Caption mdxType="Caption">Header with the default title variant.</Caption>
  </div>
  <div>
    <img width="464" src="https://user-images.githubusercontent.com/293280/175849374-6a71d8a0-da61-4745-a059-4560d053089b.png" alt="" />
    <Caption mdxType="Caption">Header with large title variant.</Caption>
  </div>
    </Box>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} mdxType="Box">
  <div>
    <img width="464" alt="" src="https://user-images.githubusercontent.com/18661030/185478040-be5a3552-9ed1-40ef-a713-3d6ac8f6b697.png" />
    <Caption mdxType="Caption">Header with custom variant and a secondary action button.</Caption>
  </div>
  <div>
    <img width="464" alt="" src="https://user-images.githubusercontent.com/18661030/185990665-5cf39b59-420a-41bf-8d65-d431134d2738.png" />
    <Caption mdxType="Caption">Command palette with a visually hidden title.</Caption>
  </div>
    </Box>
    <h3>{`Subheader region`}</h3>
    <p>{`The subheader region is an optional space for interactive controls. Use it to display a search field, a filter menu, or a local navigation component.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} mdxType="Box">
  <div>
    <img src="https://user-images.githubusercontent.com/293280/175849377-ef70f4e7-2978-415c-9da1-a2ba2f33bdc3.png" alt="" />
    <Caption mdxType="Caption">Header displaying a subheader with a search field.</Caption>
  </div>
  <div>
    <img src="https://user-images.githubusercontent.com/293280/175849376-ef25a1ba-b5b7-4ae0-8d9a-78095932886a.png" alt="" />
    <Caption mdxType="Caption">Header displaying a subheader with a local navigation.</Caption>
  </div>
    </Box>
    <h3>{`Body region`}</h3>
    <p>{`The body region is the main content of the dialog. It can contain any content that is relevant to the task at hand. The body region is scrollable if the content exceeds the height of the dialog.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} mdxType="Box">
  <div>
    <img width="650" alt="" src="https://user-images.githubusercontent.com/18661030/185506131-1436b06b-a8fb-4de8-b9b2-4715ab0a63d2.png" />
    <Caption mdxType="Caption">Body content will scroll vertically if the content exceeds the overall dialog height.</Caption>
  </div>
  <div>
    <img width="650" alt="" src="https://user-images.githubusercontent.com/18661030/187487429-bef6924c-9f25-4ec9-9416-5800396a7b32.png" />
    <Caption mdxType="Caption">The body may contain additional content such as banners and form controls.</Caption>
  </div>
    </Box>
    <h3>{`Footer region`}</h3>
    <p>{`The footer region may be used to show confirmation actions, navigation links, or specialized actions. Primary actions should be aligned to the right of the footer, and grouped by additional related actions. Secondary actions may be shown aligned to the left side.`}</p>
    <p>{`If the content area has overflow scrolling, a divider should be added between the footer and content area. Otherwise, showing a divider is optional.`}</p>
    <img src="https://user-images.githubusercontent.com/293280/175850630-9f3df284-c69b-4d16-9e57-ea04a0202d04.png" width="960" alt="" />
    <Caption mdxType="Caption">Use buttons in a footer to let the user complete a task.</Caption>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} mdxType="Box">
  <div>
    <img src="https://user-images.githubusercontent.com/293280/175850639-a77d537e-2807-47d2-b456-a298d5f44918.png" alt="" />
    <Caption mdxType="Caption">Footer with small buttons aligned to the right.</Caption>
  </div>
  <div>
    <img src="https://user-images.githubusercontent.com/293280/175850637-3b193552-b35f-4b07-b2d6-4ca546c8131a.png" alt="" />
    <Caption mdxType="Caption">Footer with an auxiliary action aligned to the left.</Caption>
  </div>
    </Box>
    <h3>{`Backdrop`}</h3>
    <p>{`Dialog displays with a `}<strong parentName="p">{`backdrop`}</strong>{`, which dims the rest of the page. By default, clicking on the `}<strong parentName="p">{`backdrop`}</strong>{` will dismiss the dialog. However, if the dialog contains a form that can have unsaved changes, the `}<strong parentName="p">{`backdrop`}</strong>{` won't dismiss the dialog, regardless of the state of the form.`}</p>
    <h3>{`Spacing`}</h3>
    <p>{`The edges of dialog are free of text and heavy visuals to visually distinguish the dialog as an elevated surface, alongside its shadow. Content within the body and header should remain within the 16px safe area. However, inner components that have transparent or discrete backgrounds may bleed into the padding for visual alignment.`}</p>
    <img alt="" src="https://user-images.githubusercontent.com/293280/174925634-603a27cf-2281-4a3a-9078-d1b8138e0d09.png" width="960" />
    <Caption mdxType="Caption">
  1. A search field in the Subheader region appears closer to the edges. While the search icon remains within the safe
  area.
  <br />
  2. Close button placed `8px` away from the edges. Note that the "×" glyph remains within the safe area.
  <br />
  3. Action list items in the Body region.
  <br />
  4. Footer buttons always appear inside the safe area. <br />
    </Caption>
    <h2>{`Size`}</h2>
    <p>{`Dialog offers a range of sizes from `}<inlineCode parentName="p">{`small`}</inlineCode>{` to `}<inlineCode parentName="p">{`xlarge`}</inlineCode>{`, with the default size set to `}<inlineCode parentName="p">{`medium`}</inlineCode>{`.`}</p>
    <p>{`Each size defines a width and a maximum height. By default the dialog height will adjust to the body content. If the maximum height is reached, the body contents will scroll.`}</p>
    <p>{`A dialog sizing is constrained by the viewport dimensions. Dialogs will not grow beyond the boundaries of the viewport.`}</p>
    <p>{`Small viewports force dialogs to remain small. All dialogs need to work on viewports of at least `}<inlineCode parentName="p">{`320px`}</inlineCode>{` of width, and `}<inlineCode parentName="p">{`256px`}</inlineCode>{` of height.`}</p>
    <h3>{`Small`}</h3>
    <img width="1292" alt="" src="https://user-images.githubusercontent.com/18661030/187552557-452e7253-9fdc-4f58-8ad7-9cd016d7786e.png" />
    <Caption mdxType="Caption">Small dialogs have a width of 320px, and a max height of 256px.</Caption>
    <h3>{`Medium (default)`}</h3>
    <p>{`Medium is the default size for Dialog and should be used for most tasks such as completing a form or selecting an option from a list. The medium portrait size is suitable for longer lists of items that require more vertical space for the body.`}</p>
    <img width="1292" alt="" src="https://user-images.githubusercontent.com/18661030/187552600-06dcbd81-90b5-45aa-81ce-89f8d9763508.png" />
    <Caption mdxType="Caption">
  Medium dialogs have a width of 480px, and a max height of 320px. Medium portrait oriented Dialogs have a width of
  480px, and a max height of 600px.
    </Caption>
    <h3>{`Large`}</h3>
    <p>{`Large dialogs are suitable for content that requires more horizontal space for the body compared to size medium, such as a comment box.`}</p>
    <img width="1292" alt="" src="https://user-images.githubusercontent.com/18661030/187482693-04eb3780-89e1-4705-a55b-5fa8ba0f9865.png" />
    <Caption mdxType="Caption">Large dialogs have a width of 640px, and a max height of 432px.</Caption>
    <h3>{`Extra large`}</h3>
    <p>{`Before using an extra large dialog, consider whether or not the content would be more appropriate for a new page.`}</p>
    <img width="1292" alt="" src="https://user-images.githubusercontent.com/18661030/187482817-9d82fe3e-c24d-4eca-9b4b-c74fa32450d4.png" />
    <Caption mdxType="Caption">Extra large dialogs have a width of 960px, and a max height of 600px.</Caption>
    <h2>{`Usage`}</h2>
    <h3>{`Keep content simple`}</h3>
    <p>{`Dialogs are meant to be interacted with in a single context. Avoid creating a whole page inside a dialog. Prefer single-column layouts that convey a clear goal from the moment the dialog is opened.`}</p>
    <p>{`However, dialogs are powerful tools when creating space for single-context interfaces. When designing a new feature to support a main interface, start by considering if the new feature can be created under a single context.`}</p>
    <p>{`If the answer is yes, designing the feature inside a dialog provides an easy way to add responsive support, and avoid deeper navigations. If the answer is no, or if a side navigation is required for the feature, consider designing a new page.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="464" alt="" src="https://user-images.githubusercontent.com/18661030/185224257-a40112ff-aaf3-4947-acf0-6b4020e023df.png" />
    <Caption mdxType="Caption">Prefer a single column layout.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="464" alt="" src="https://user-images.githubusercontent.com/18661030/185224316-0680b89a-8514-4336-9147-64aac722cd9a.png" />
    <Caption mdxType="Caption">Avoid side navigation within a Dialog.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Nested dialogs`}</h3>
    <p>{`It is acceptable to have up to two nested dialogs as long as the dialog is implemented with `}<a parentName="p" {...{
        "href": "#trigger-elements-and-keyboard-focus"
      }}>{`expected focus management behavior`}</a>{`. Upon closing the second nested dialog, focus should return to the trigger control within the first dialog. When the two stacked dialogs are open, pressing `}<inlineCode parentName="p">{`Esc`}</inlineCode>{` should only close the top-level dialog. When one clicks outside of the second dialog with a mouse, only the second dialog should close.`}</p>
    <p>{`However, having more than three nested dialogs is discouraged because it adds unnecessary complexity to the user experience and can be disorienting for some users.`}</p>
    <h3>{`Deep linking`}</h3>
    <p>{`It's crucial to consider if the dialog should be accessible via a link. For instance, if our users intend to link to this specific content from outside GitHub.`}</p>
    <p>{`While we can rely on parameters in the URL to open dialogs on page load, this approach can disorient users because they might not understand to what context this dialog belongs to since the rest of the page will be dimmed by a `}<a parentName="p" {...{
        "href": "#backdrop"
      }}>{`backdrop`}</a>{`.`}</p>
    <p>{`In such situations, it is often more advisable to avoid using a dialog altogether and instead display the additional information directly on the page or create a new dedicated page. This approach allows for better integration of the content within the overall website structure.`}</p>
    <h3>{`Dialogs should work well on any device`}</h3>
    <p>{`Dialogs must adjust their designs to fit in smaller viewports. Make sure the overlay contents work with all supported sizes and input methods.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} mdxType="Box">
  <div>
    <CustomVideoPlayer width="100%" src="https://user-images.githubusercontent.com/18661030/185507523-74e0acff-6980-439e-84c5-422b16470c9e.mp4" mdxType="CustomVideoPlayer" />
    <Caption mdxType="Caption">Center aligned dialog becomes fullscreen on narrow viewport.</Caption>
  </div>
  <div>
    <CustomVideoPlayer width="100%" src="https://user-images.githubusercontent.com/18661030/185507508-298f9378-3f26-4e45-9809-5aef3d8cd3e2.mp4" mdxType="CustomVideoPlayer" />
    <Caption mdxType="Caption">Center aligned dialog becomes a bottom sheet on narrow viewport.</Caption>
  </div>
    </Box>
    <h2>{`Regular-viewport placement`}</h2>
    <h3>{`Centered`}</h3>
    <p>{`By default, dialog appears centered in the viewport, with a visible backdrop that dims the rest of the window for focus. Centered dialogs are surrounded by a safe area of `}<inlineCode parentName="p">{`16px`}</inlineCode>{` between the frame and the viewport for all sizes.`}</p>
    <img width="960" alt="" src="https://user-images.githubusercontent.com/18661030/185224429-9f0af3ee-3e11-4f94-a5f6-746ed8a5c300.png" />
    <Caption mdxType="Caption">Dialog centered within a desktop screen.</Caption>
    <h3>{`Side sheets`}</h3>
    <p>{`Side sheets slide from the right or left edges of the viewport and are always fixed to both the top and bottom of the viewport. `}<strong parentName="p">{`Left side sheets are reserved for global navigation drawers. Right side sheets are used for global actions, but can also be considered for quick previews in full-width pages.`}</strong></p>
    <p>{`Side sheets take the entire height of the viewport, and should be used sparingly. Don't use side sheets to present create/edit forms, or flows that may contain a lot of information. For that, use a page instead.`}</p>
    <img width="960" alt="placement-desktop-1" src="https://user-images.githubusercontent.com/18661030/185214096-8ad908b4-2cbd-467d-8e74-bad6948c5a2d.png" />
    <Caption mdxType="Caption">Left aligned side sheet is reserved for global navigation only.</Caption>
    <img width="960" alt="placement-desktop-2" src="https://user-images.githubusercontent.com/18661030/185214094-419d2271-dbb9-4e78-90e6-4433ed9c42fb.png" />
    <Caption mdxType="Caption">
  Right aligned side sheet can be used for global actions, but may also be considered for quick previews or
  configuration panels.
    </Caption>
    <p>{`Side sheets are still considered as dialogs, which means that interacting with the content in the background is not possible.
This needs to be indicated by a `}<a parentName="p" {...{
        "href": "#backdrop"
      }}>{`backdrop`}</a>{` and should always cover the global navigation.
This prevents multiple side sheets from appearing simultaneously.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="464" alt="" src="https://github.com/primer/design/assets/980622/6501c520-70da-49a2-8b29-a9f028c6a6e3" />
    <Caption mdxType="Caption">Side sheets appear on top of the page (including the navigation).</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="464" alt="" src="https://github.com/primer/design/assets/980622/f05aa559-a866-47cf-84a2-f4897f1da08c" />
    <Caption mdxType="Caption">Avoid side sheets that allow interactions with the rest of the page.</Caption>
  </Dont>
    </DoDontContainer>
    <p>{`Right side sheets serve multiple purposes, including facilitating global actions and providing quick previews in full-width pages.
However, it is important to exercise caution when implementing side sheets in pages that are not full-width. The objective is to minimize the distance users need to travel with their mouse cursor to access the side sheet, especially on wide screens.
This consideration is essential for maximizing productivity.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="464" alt="" src="https://github.com/primer/design/assets/980622/84585f5e-fad5-451c-8c53-87972f69030d" />
    <Caption mdxType="Caption">Use side sheets to provide a quick preview in full-width pages.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="464" alt="" src="https://github.com/primer/design/assets/980622/df59604f-c459-4aff-8db7-3e23000c91ac" />
    <Caption mdxType="Caption">
      Unless it's a global action, avoid side sheets in pages where content does not stretch the full width of the page.
    </Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Narrow-viewport placement`}</h2>
    <h3>{`Bottom sheets`}</h3>
    <p>{`A bottom sheet is a variant supported on narrow viewports made to facilitate reachability. dialogs that appear centered in large screens may adapt to appear as a bottom sheet on small screens.`}</p>
    <p>{`A bottom sheet always dims the rest of the screen for focus and takes up the full width of the viewport. Tapping on the backdrop dismisses the dialog.`}</p>
    <p>{`Use a bottom to indicate that the user is still in the context of the main page, and that the dialog is a secondary action.`}</p>
    <img width="960" alt="bottom-sheet" src="https://user-images.githubusercontent.com/18661030/185214084-43e9573a-1a3b-4b82-b121-bd03897f48ac.png" />
    <Caption mdxType="Caption">Bottom sheet in portrait mode.</Caption>
    <p>{`In landscape mode, a bottom sheet has a maximum width of `}<inlineCode parentName="p">{`480px`}</inlineCode>{`, and is centered horizontally.`}</p>
    <img width="960" alt="overlay-sheet-landscape" src="https://user-images.githubusercontent.com/18661030/185214089-a1bb1a70-914b-4a8f-b395-9c279a88a1fe.png" />
    <Caption mdxType="Caption">Bottom sheet in landscape mode.</Caption>
    <h3>{`Full-screen`}</h3>
    <p>{`Full-screen dialogs may be used to present content that needs all the available space on narrow viewports, or to act as a new page without navigating away from the main page. Dialogs that have one or more input fields should always use the full-screen variant.`}</p>
    <img width="960" alt="" src="https://user-images.githubusercontent.com/18661030/185214079-d30342c1-6061-4a31-ab65-c0cef0f00278.png" />
    <Caption mdxType="Caption">Full-screen Dialog in portrait mode.</Caption>
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`Modal dialogs must be clearly announced as dialogs by screen readers.`}</p>
    <p>{`Modal dialogs need to have an accessible name that informs the user of the purpose of the dialog. Generally, this accessible name should be presented visually as the title of the dialog. The title should concisely describe the contents or purpose of the modal.`}</p>
    <p>{`When a modal dialog is opened, focus must be programmatically moved into the dialog. In most cases, the first focusable control inside the dialog should receive focus.`}</p>
    <p>{`Once inside the modal dialog, focus must remain in the dialog - users must be prevented from navigating out of the dialog and back into the underlying page.`}</p>
    <p>{`The underlying page behind the modal dialog must be hidden from screen readers, to avoid users accidentally navigating out of the dialog (for instance, using browse mode or heading navigation).`}</p>
    <p>{`Dialogs must have a way to be closed by the user. In most cases, this functionality will be provided as an explicit graphical "X" close control, though depending on the purpose of the dialog, users may instead have to activate more specific controls (such as "OK" / "Cancel" buttons).`}</p>
    <p>{`When a dialog is closed, focus returns to the most logical location in the page – in general, this is the trigger control that originally opened the dialog.`}</p>
    <p>{`Dialog's title and close button are required to ensure an accessible experience for everyone. Further, keyboard and focus behavior must be considered.`}</p>
    <h3>{`Trigger elements and keyboard focus`}</h3>
    <p>{`Dialogs are initially hidden, and can be opened by an element with `}<inlineCode parentName="p">{`role="button"`}</inlineCode>{`, called a `}<strong parentName="p">{`trigger`}</strong>{`. The role `}<inlineCode parentName="p">{`button`}</inlineCode>{` allows the `}<strong parentName="p">{`trigger`}</strong>{` to be a relatively complex element, such as an action list item.`}</p>
    <p>{`Dialogs must be fully functional using the keyboard and assistive technology. Pressing `}<inlineCode parentName="p">{`Esc`}</inlineCode>{` must dismiss a dialog, also returning focus to the `}<strong parentName="p">{`trigger`}</strong>{` that opened it.`}</p>
    <CustomVideoPlayer width="100%" loop src="https://user-images.githubusercontent.com/18661030/185507547-0843b014-0b90-48f5-9e4f-746caa42b4e6.mp4" mdxType="CustomVideoPlayer" />
    <Caption mdxType="Caption">Clicking the backdrop or hitting the `Esc` key should dismiss the dialog.</Caption>
    <p>{`When a dialog is opened, the first interactive element (typically the close button) is focused. For a scenario like a command palette with an `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` next to the close button, the `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` will recieve focus first. Hitting `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` will cycle through all interactive elements within the dialog. To escape the focus trap, hitting the `}<inlineCode parentName="p">{`Esc`}</inlineCode>{` key or clicking on the backdrop will close the dialog. While the dialog is open, page scrolling is disabled and becomes `}<inlineCode parentName="p">{`inert`}</inlineCode>{`.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} marginBottom={5} mdxType="Box">
  <div>
    <img src="https://user-images.githubusercontent.com/18661030/185997280-fb36be9c-bfde-463b-b33c-9f25412683d2.png" alt="" />
    <Caption mdxType="Caption">Dialogs can be triggered by buttons with a mouse click or keypress.</Caption>
  </div>
  <div>
    <img src="https://user-images.githubusercontent.com/18661030/185997278-6fc2b938-6453-47d7-99ae-0411354eb06e.png" alt="" />
    <Caption mdxType="Caption">The first interactive item inside a dialog is focused when opened.</Caption>
  </div>
    </Box>
    <img width="960" alt="" src="https://user-images.githubusercontent.com/18661030/185997276-25cd8796-42e2-41f6-b5be-551f6a02349b.png" />
    <Caption mdxType="Caption">When a dialog is closed, focus is returned to the trigger button.</Caption>
    <h3>{`Live region usage`}</h3>
    <p>{`Use extra caution when utilizing a live region that exists outside of the `}<inlineCode parentName="p">{`<Dialog>`}</inlineCode>{` component.
This is because some browser and screen reader combinations may ignore the live region entirely if it does not already exist within the dialog.`}</p>
    <p>{`If you need to use a live region while a dialog is active, place the live region within the dialog, rather than outside of it.`}</p>
    <h3>{`Built-in accessibility features`}</h3>
    <p>{`The Rails version of the component generates a `}<inlineCode parentName="p">{`<dialog>`}</inlineCode>{` element with `}<inlineCode parentName="p">{`aria-modal="true"`}</inlineCode>{`.`}</p>
    <p>{`The React version of the component generates a `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` element with `}<inlineCode parentName="p">{`role="dialog"`}</inlineCode>{` and `}<inlineCode parentName="p">{`aria-modal="true"`}</inlineCode>{`.`}</p>
    <p>{`When provided, the `}<inlineCode parentName="p">{`title`}</inlineCode>{` prop for the component is rendered as an `}<inlineCode parentName="p">{`<h1>`}</inlineCode>{` heading, and is explicitly referenced by the dialog using `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` to provide an accessible name for the dialog.`}</p>
    <p>{`By default, the generated dialog includes an "X" graphical close control, which receives focus automatically when the dialog is opened.`}</p>
    <p>{`In the Rails version of the component, you can use the `}<inlineCode parentName="p">{`autofocus`}</inlineCode>{` property on interactive elements in the dialog's content to move the initial focus directly to a specific control. However, you should carefully consider when this approach is necessary – in general, it is best to keep dialogs consistent and let focus go to the "X" close control.`}</p>
    <p>{`Focus is maintained inside the dialog. The `}<inlineCode parentName="p">{`aria-modal="true"`}</inlineCode>{` on the dialog hides the underlying page from screen readers.`}</p>
    <p>{`When the dialog is closed, focus is automatically moved back to the last element in the underlying page that had focus - generally, the control the triggered the dialog.`}</p>
    <h3>{`Implementation requirements`}</h3>
    <p>{`Make sure to provide a sufficiently descriptive title for the modal dialog. The title should concisely describe the contents or purpose of the modal.`}</p>
    <p>{`In cases where the original trigger element that opened the dialog is not present in the page any more, or where the dialog was not opened as a result of a user action, you will need to explicitly handle which element will receive focus programmatically once the dialog is closed.`}</p>
    <h4>{`Integration tests`}</h4>
    <ul>
      <li parentName="ul">{`The modal dialog has a sufficiently descriptive title rendered has a heading rendered as an `}<inlineCode parentName="li">{`<h1>`}</inlineCode>{` heading`}</li>
      <li parentName="ul">{`Once the dialog is closed, focus is returned to the most appropriate location in the page`}</li>
    </ul>
    <h4>{`Component tests`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The modal dialog programmatically exposes its role - either using `}<inlineCode parentName="p">{`<dialog>`}</inlineCode>{` or `}<inlineCode parentName="p">{`role="dialog"`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The modal dialog programmatically conveys that it is modal with `}<inlineCode parentName="p">{`aria-modal="true"`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`title`}</inlineCode>{` prop of the component is rendered as an `}<inlineCode parentName="p">{`<h1>`}</inlineCode>{` heading, that it has an `}<inlineCode parentName="p">{`id`}</inlineCode>{` attribute, and that this `}<inlineCode parentName="p">{`id`}</inlineCode>{` is referenced from the dialog container itself with `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` to provide an accessible name`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When the modal dialog is opened, focus is moved into the dialog - generally, to the first focusable control inside the dialog`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In the Rails implementation, the control specified by the author with `}<inlineCode parentName="p">{`autofocus`}</inlineCode>{` receives focus when the modal dialog is opened`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Focus remains inside the dialog - using `}<inlineCode parentName="p">{`Tab`}</inlineCode>{`/`}<inlineCode parentName="p">{`Shift+Tab`}</inlineCode>{` must not result in focus landing in the underlying page`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When the modal dialog is closed, focus is returned to the last element in the underlying page that was focused at the time the dialog was opened - typically, this will be the trigger element that opened the dialog`}</p>
      </li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Dialog" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      